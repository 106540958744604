import React from "react";
import Page from "../components/Page";
import Button from "../components/elements/Buttons/Button";
import Layout from "../components/Layout";
import Space from "../components/elements/Space/Space";
import FullImageBanner from "../components/components/FullImageBanner/FullImageBanner";
import ContactForm from "../components/components/ContactForm/ContactForm";
import Numbers, { Number } from "../components/components/Numbers/Numbers";
import Job from "../components/components/JobPosition/JobPosition";
import Text from "../components/atoms/Text/Text";
import CentralLayout from "../components/atoms/CentralLayout/CentralLayout";
import Section from "../components/atoms/Section/Section";

import { scrollToContact } from "../utility/function";
import GreenTitle from "../components/atoms/GreenTitle/GreenTitle";
import Testimonial, { TestimonialSlider } from "../components/elements/Testimonial/Testimonial";
import ClientsTestimonialsImages from "../components/components/ClientsTestimonialsImages/ClientsTestimonialsImages";

const Assicurativo = () => (
  <Page title='Lavora con noi' description='' image='/favicon.png'>
    <Layout navigationCallback={() => scrollToContact()}>
      <Space mobile={15} table={15} desktop={15} />

      <Space mobile={10} table={10} desktop={10} />
      <CentralLayout>
        <GreenTitle caps>Lavora con noi</GreenTitle>
        <h1>
          Crediamo in un'azienda
          <br />
          fatta di persone
        </h1>
      </CentralLayout>

      <Space mobile={10} table={15} desktop={20} />
      {/* 
      <Job title='Freelance fullstack web developer' description='(mid-level) - Possibilità remoto' to='/fullstack-web-developer'></Job> 
      */}
      <Job title='Al momento non abbiamo posizioni aperte 👪' description='' to='/'></Job>

      <Space mobile={20} table={20} desktop={20} />

      <Numbers text={<></>}>
        <Number number={"5"} text={"Persone nel team"} />
        <Number number={"<30"} text={"Età media del team"} />
        <Number number={"460+"} text={"Caffé presi"} />
      </Numbers>

      <Space mobile={0} table={5} desktop={10} />

      <Space mobile={20} table={20} desktop={20} />

      <ContactForm text_line1='Vuoi scambiare due chiacchiere?' text_line2='Contattaci!' />
    </Layout>
  </Page>
);

export default Assicurativo;
